import { createTypedRoute } from 'modules/typed-routes';

export enum DOCUMENTS_TABS {
  MEDIA = 'media',
  OTHER = 'other',
  DOCS_SIGNED = 'signed',
  SURVEY_HISTORY = 'survey-history',
}

export enum HISTORY_MEETINGS_TABS {
  CLINICAL_MEETINGS = 'clinical-meetings',
  SUPPORT_MEETINGS = 'support-meetings',
}

export const APP_ROUTES = {
  HOME: createTypedRoute('/'),
  PRESCRIPTIONS: createTypedRoute('/prescriptions'),
  DOCUMENTS: createTypedRoute<{ tab: DOCUMENTS_TABS }>('/documents'),
  DIET_DIARY: createTypedRoute('/diet-diary'),
  SIGN_DOCUMENT: createTypedRoute<{ documentID: string }>('/documents/:documentID/sign'),
  HISTORY_MEETINGS: createTypedRoute<{ tab: HISTORY_MEETINGS_TABS }>('/history-meetings'),
  PROFILE: createTypedRoute('/profile'),
  SURVEY: createTypedRoute<{ surveyID: string }>('/survey/:surveyID'),

  INDEX: createTypedRoute<{ redirect?: string }>('/app'),
  LOGIN: createTypedRoute<{ redirect?: string }>('/login'),
  LOGIN_CODE: createTypedRoute<{ mobilePhone: string; redirect?: string }>('/login/:mobilePhone'),
  BACKDOOR: createTypedRoute<{ appIdentityUserID: string; secret: string; redirect?: string }>(
    '/__backdoor/:appIdentityUserID/:secret',
  ),
  NOT_FOUND: createTypedRoute('/not-found'),

  PATIENT_HEALTH_SURVEY: createTypedRoute<{ surveyID: string }>('/patient/survey/:surveyID'),
  PATIENT_HEALTH_SURVEY_THANKS: createTypedRoute('/patient/survey/thanks'),

  PATIENT_SIGN_DOCUMENT: createTypedRoute<{ documentID: string }>(
    '/patient/documents/:documentID/sign',
  ),
  PATIENT_SIGN_DOCUMENT_THANKS: createTypedRoute<{ filePath: string }>(
    '/patient/documents/sign/thanks',
  ),
};
