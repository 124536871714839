import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  apiNotebooks,
  NOTEBOOK_SURVEY_HEALTH_EN_ROW_INDEX,
  NOTEBOOK_SURVEY_HEALTH_HE_ROW_INDEX,
  ServiceNotebooks,
  SurveyQuestion,
} from 'services/notebooks';
import { ServiceUserPatientProfileSessionItems } from 'services/user-patient-profile-session-items';
import { ServiceUsers } from 'services/users';
import { AppAsyncThunkConfig } from 'store';
import { actionAccountUpdateUser, selectAccountUser } from 'store/accounts';
import { apiRtk, parseErrorData, RTK_TAGS } from 'utils/service';

interface ActionSurveysSubmitPayload {
  answers: Record<string, any>;
  survey: SurveyQuestion;
}
export const actionSurveysSubmit = createAsyncThunk<
  { id: string },
  ActionSurveysSubmitPayload,
  AppAsyncThunkConfig
>(`SURVEYS/getSurvey`, async ({ survey, answers }, { getState, dispatch }) => {
  try {
    const { id, notebookFieldInputs } = survey;
    const state = getState();
    const user = selectAccountUser(state);

    if (!user) {
      throw new Error('unexpected-behaviour');
    }

    const _answersPromises = notebookFieldInputs
      .filter((question) => {
        const value = answers[question.id];
        const isEmpty = value === null || value === undefined || value === '';

        return !isEmpty;
      })
      .map(async (question, i) => {
        const value = answers[question.id];

        let createPayload = {
          fieldInputID: String(question.fieldInputID),
          entryValueString: String(value),
          internalSystemValue: ServiceUserPatientProfileSessionItems.makeInternalSystemValue(value),
          externalSystemValue: '',
        };

        // handle select
        if (question.parameter?.isSelect && question.dataSource) {
          const args = ServiceNotebooks.getDynamicSourceArgs(question.dataSource);
          await dispatch(apiNotebooks.endpoints.getDynamicSource.initiate(args));
          const { data = [] } = await apiNotebooks.endpoints.getDynamicSource.select(args)(state);

          const list = data.map(ServiceNotebooks.makeDynamicSourceOption(question.dataSource));
          const selectedItem = list.find((item) => item.id === value);

          if (!selectedItem) {
            throw new Error(`${question.dataSource.dataSourceName}: can-not-find-item`);
          }

          createPayload['externalSystemValue'] = String(selectedItem.id);
          createPayload['entryValueString'] = String(selectedItem.label);
          createPayload['internalSystemValue'] =
            ServiceUserPatientProfileSessionItems.makeInternalSystemValue(selectedItem);
        }

        return createPayload;
      });

    const _answers = await Promise.all(_answersPromises);

    const { data: session } = await ServiceUsers.fillHealthSurvey({
      notebookID: String(id),
      userPatientProfileID: String(user.appUserID),
      answers: _answers,
    });

    dispatch(apiRtk.util.invalidateTags([{ type: RTK_TAGS.SESSIONS }]));

    if (
      survey.rowIndex === NOTEBOOK_SURVEY_HEALTH_EN_ROW_INDEX ||
      survey.rowIndex === NOTEBOOK_SURVEY_HEALTH_HE_ROW_INDEX
    ) {
      dispatch(actionAccountUpdateUser({ mustFillHealthSurvey: false }));
    }

    return { id: session.id };
  } catch (e: any) {
    throw parseErrorData(e);
  }
});
