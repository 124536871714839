import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { all } from 'modules/typed-saga';
import { PersistMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { apiRtk } from 'utils/service';
import packages from '../../package.json';
import { reducerAccounts, sagasAccount } from './accounts';
import { sagasDietDiaries } from './diet-diaries';
import { reducerLabels, sagasLabels } from './labels';
import { reducerSignDocument, sagasSignDocument } from './sign-document';
import { reducerSurveys, sagasSurveys } from './surveys';
import { sagasUploadedFiles } from './uploaded-files';

const VERSION = parseInt(packages.version.replace(/\./gi, ''));

const migrateStore: PersistMigrate = (state) => {
  if (VERSION === state?._persist.version) {
    return Promise.resolve(state);
  } else {
    return Promise.resolve(undefined);
  }
};

const rootReducer = combineReducers({
  labels: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'labels',
      storage,
      whitelist: ['languageID'],
    },
    reducerLabels,
  ),
  accounts: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'accounts',
      storage,
      whitelist: [
        'isInstalled',
        'installDoNotAskAgain',
        'installLastDate',
        'notificationsDoNotAskAgain',
        'permissions',
      ],
    },
    reducerAccounts,
  ),
  surveys: persistReducer(
    {
      version: VERSION,
      migrate: migrateStore,
      key: 'surveys',
      storage,
    },
    reducerSurveys,
  ),
  signDocument: reducerSignDocument,

  [apiRtk.reducerPath]: apiRtk.reducer,
});

const sagaMiddleware = createSagaMiddleware();

function* rootSaga() {
  yield all([
    ...sagasSignDocument,
    ...sagasAccount,
    ...sagasSurveys,
    ...sagasLabels,
    ...sagasUploadedFiles,
    ...sagasDietDiaries,
  ]);
}

export const appStore = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
      immutableCheck: false,
    })
      .concat(sagaMiddleware)
      .concat(apiRtk.middleware);
  },
  devTools: process.env.NODE_ENV !== 'production',
});
sagaMiddleware.run(rootSaga);

export type AppState = ReturnType<typeof appStore.getState>;
export type AppDispatch = typeof appStore.dispatch;
export type AppAsyncThunkConfig = {
  state: AppState;
  dispatch: AppDispatch;
  serializedErrorType: Error;
};

export const appPersistor = persistStore(appStore);
