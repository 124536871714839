import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionAccountLogout } from 'store/accounts';
import { actionSurveysSubmit } from 'store/surveys/actions';

type InitState = { [surveyID: string]: SurveyItem };
type Answers = { [questionID: string]: any };

interface SurveyItem {
  surveyID: string;
  index: number;
  answers: Answers;
}

const initState = (): InitState => {
  return {};
};

const slice = createSlice({
  name: 'SURVEYS',
  initialState: initState(),
  reducers: {
    actionSurveySetIndex(state, action: PayloadAction<{ surveyID: string; index: number }>) {
      const { surveyID, index } = action.payload;
      const item = state[surveyID] ?? { index: 0, answers: {} };
      state[surveyID] = { ...item, index };
    },
    actionSurveySetAnswers(state, action: PayloadAction<{ surveyID: string; answers: Answers }>) {
      const { surveyID, answers } = action.payload;
      const item = state[surveyID] ?? { index: 0, answers: {} };
      state[surveyID] = { ...item, answers: answers };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actionSurveysSubmit.fulfilled, (state, action) => {
      const surveyID = action.meta.arg.survey.id;
      if (surveyID) {
        delete state[surveyID];
      }
    });
    builder.addCase(actionAccountLogout.fulfilled, (state, action) => {
      return initState();
    });
  },
});
export const { actionSurveySetIndex, actionSurveySetAnswers } = slice.actions;
export const reducerSurveys = slice.reducer;
