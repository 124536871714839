import {
  API_ACTIVITIES,
  ClinicalMeeting,
  DashboardClinicalMeeting,
  DashboardSessionItem,
  DashboardSupportMeeting,
  SessionItem,
  SESSION_FIELD_INPUT_TYPE_IDS,
  SupportMeeting,
} from 'services/activities/models';
import * as dynamic from 'utils/dynamic';
import { apiRtk, transformResponseDynamic } from 'utils/service';

export * from './models';

export const apiActivities = apiRtk.injectEndpoints({
  endpoints: (build) => {
    return {
      getDashboardClinicalMeeting: build.query<DashboardClinicalMeeting[], void>({
        query: () => ({
          url: API_ACTIVITIES.GET_CLINICAL_MEETINGS,
          params: {
            select: dynamic.select<ClinicalMeeting>(
              'id',
              'clinicalMeetingType.title as title',
              'clinicalMeetingType.color as color',
              'clinicalMeetingType.icon as icon',
              'clinicalMeetingTypeID',
              'meetingFromDateTime',
              'meetingToDateTime',
              'new { userEmployeeProfile.userCrmProfilePermission.position, userEmployeeProfile.userPhoto, userEmployeeProfile.fullName } as employee',
            ),
            filter: dynamic.makeFilter<ClinicalMeeting>(
              'meetingFromDateTime',
              'DateTime.Now',
              dynamic.more,
            ),
            orderBy: dynamic.orderBy('meetingFromDateTime', 'asc'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getClinicalMeetingsHistory: build.query<DashboardClinicalMeeting[], void>({
        query: () => ({
          url: API_ACTIVITIES.GET_CLINICAL_MEETINGS,
          params: {
            select: dynamic.select<ClinicalMeeting>(
              'id',
              'clinicalMeetingType.title as title',
              'clinicalMeetingType.color as color',
              'clinicalMeetingType.icon as icon',
              'clinicalMeetingTypeID',
              'meetingFromDateTime',
              'meetingToDateTime',
              'new { userEmployeeProfile.userCrmProfilePermission.position, userEmployeeProfile.userPhoto, userEmployeeProfile.fullName } as employee',
            ),
            filter: dynamic.makeFilter<ClinicalMeeting>(
              'meetingFromDateTime',
              'DateTime.Now',
              dynamic.less,
            ),
            orderBy: dynamic.orderBy('meetingFromDateTime', 'desc'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getDashboardSupportMeeting: build.query<DashboardSupportMeeting[], void>({
        query: () => ({
          url: API_ACTIVITIES.GET_SUPPORT_MEETINGS,
          params: {
            select: dynamic.select<SupportMeeting>(
              'id',
              'includeMeetingTime',
              'meetingFromDateTime',
              'meetingToDateTime',
              'new { userEmployeeProfile.userCrmProfilePermission.position, userEmployeeProfile.userPhoto, userEmployeeProfile.fullName } as employee',
              'supportMeetingTypeID',
              'supportMeetingType.icon as icon',
              'supportMeetingType.title as title',
              'supportMeetingType.color as color',
            ),
            filter: dynamic.makeFilter<SupportMeeting>(
              'meetingFromDateTime',
              'DateTime.Now',
              dynamic.more,
            ),
            orderBy: dynamic.orderBy<SupportMeeting>('meetingFromDateTime', 'asc'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getSupportMeetingsHistory: build.query<DashboardSupportMeeting[], void>({
        query: () => ({
          url: API_ACTIVITIES.GET_SUPPORT_MEETINGS,
          params: {
            select: dynamic.select<SupportMeeting>(
              'id',
              'includeMeetingTime',
              'meetingFromDateTime',
              'meetingToDateTime',
              'new { userEmployeeProfile.userCrmProfilePermission.position, userEmployeeProfile.userPhoto, userEmployeeProfile.fullName } as employee',
              'supportMeetingTypeID',
              'supportMeetingType.icon as icon',
              'supportMeetingType.title as title',
              'supportMeetingType.color as color',
            ),
            filter: dynamic.makeFilter<SupportMeeting>(
              'meetingFromDateTime',
              'DateTime.Now',
              dynamic.less,
            ),
            orderBy: dynamic.orderBy<SupportMeeting>('meetingFromDateTime', 'desc'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
      getDashboardSessionItems: build.query<DashboardSessionItem[], void>({
        query: () => ({
          url: API_ACTIVITIES.GET_SESSION_ITEMS,
          params: {
            filter: dynamic
              .mergeFilters(
                dynamic.makeFilter<SessionItem>(
                  'fieldInput.inputType.isWeight',
                  true,
                  dynamic.equals,
                ),
                dynamic.makeFilter<SessionItem>('entryValueNumber', null, dynamic.notEquals),
                dynamic.makeFilter(
                  'fieldInput.inputTypeID',
                  SESSION_FIELD_INPUT_TYPE_IDS.HOME,
                  dynamic.equals,
                ),
              )
              .join('&&'),
            select: dynamic.select<SessionItem>(
              'id',
              'userPatientProfileSession.entryDate as entryDate',
              'userPatientProfileSession.notebook.labelKey as notebookLabelKey',
              'fieldInput.labelKey as activityType',
              'entryValueNumber as weight',
            ),
            orderBy: dynamic.orderBy('userPatientProfileSession.entryDate', 'asc'),
          },
        }),
        transformResponse: transformResponseDynamic,
      }),
    };
  },
});
