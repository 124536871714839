import { DateValue } from 'utils/dates';
import * as yup from 'yup';
import { ValueFileUploader } from '../../utils/file-uploader';
const API = 'UserPatientProfileDietDiaries';

export const API_USER_PATIENT_PROFILE_DIET_DIARIES = {
  GET_ALL_DYNAMIC: `${API}/GetAllDynamic`,
  POST: `${API}/Create`,
  PATCH: (data: Pick<Components.Schemas.UserPatientProfileDietDiary, 'id'>) =>
    `${API}/Patch/${data.id}`,
  DELETE: (data: Pick<Components.Schemas.UserPatientProfileDietDiary, 'id'>) =>
    `${API}/Delete/${data.id}`,
};

export const schemaDietDiary = yup
  .object({
    hours: yup.number().required('rule-required').min(0).max(23).default(0),
    minutes: yup.number().required('rule-required').min(0).max(59).default(0),
    userPatientProfileID: yup.string().required('rule-required').default(''),
    dietDiaryTypeID: yup.string().required('rule-required').default(''),
  })
  .shape(
    {
      description: yup
        .string()
        .when('fileURLs', {
          is: (fileURL: Array<any>) => fileURL.length === 0,
          then: yup.string().required('required'),
          otherwise: yup.string().nullable(),
        })
        .max(600, 'rule-max-length')
        .default('')
        .trim(),
      fileURLs: yup.array().of(yup.mixed()).max(8, 'rule-max').default([]),
    },
    [['fileURLs', 'description']],
  );

export interface UserPatientProfileDietDiaryPostInput
  extends Components.Schemas.UserPatientProfileDietDiary {
  fileURLs: ValueFileUploader[];
}
export interface UserPatientProfileDietDiaryDeleteInput
  extends Pick<Components.Schemas.UserPatientProfileDietDiary, 'id'> {}

export interface GetListUserPatientProfileDietDiariesArg {
  userPatientProfileID: string;
  date: DateValue;
}

export interface GetListUserPatientProfileDietDiariesItemOutput
  extends Pick<
    Components.Schemas.UserPatientProfileDietDiary,
    | 'id'
    | 'dietDiaryTypeID'
    | 'description'
    | 'isViewed'
    | 'userEmployeeProfile'
    | 'entryDate'
    | 'replyDate'
    | 'reply'
  > {
  userEmployeeProfile: Pick<Components.Schemas.UserEmployeeProfile, 'fullName' | 'userPhoto'>;
  fileURLs: string[];
}
export interface GetListUserPatientProfileDietDiariesItem
  extends GetListUserPatientProfileDietDiariesItemOutput {
  files: { fileURL: string; base64: string }[];
}
