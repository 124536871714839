import { apiApp } from 'utils/service';
import { API_USERS } from './models';

class Service {
  fillHealthSurvey(input: Components.Schemas.FillSurveyUserDto) {
    return apiApp.post<Components.Schemas.FillSurveyUserDtoOutput>(
      API_USERS.FILL_HEALTH_SURVEY,
      input,
    );
  }
}

export const ServiceUsers = new Service();
