import { apiAccounts } from 'services/accounts';
import {
  actionAccountGetUser,
  actionAccountLogout,
  actionAccountUpdateUser,
} from 'store/accounts/actions';
import { put, takeLatest } from 'typed-redux-saga';
import { apiRtk } from 'utils/service';

function* sagaWatchLogout() {
  yield* put(apiRtk.util.resetApiState());
}
function* sagaWatchUpdateUser() {
  yield* put(actionAccountGetUser({ isBackground: true }));
}

export const sagasAccount = [
  takeLatest(apiAccounts.endpoints.renewalPrescription.matchFulfilled, sagaWatchUpdateUser),
  takeLatest(actionAccountLogout.fulfilled, sagaWatchLogout),
  takeLatest(
    [
      actionAccountUpdateUser.fulfilled,
      actionAccountUpdateUser.rejected,
      apiAccounts.endpoints.updateCurrentUserInfo.matchFulfilled,
      apiAccounts.endpoints.updateCurrentUserInfoWithLog.matchFulfilled,
    ],
    sagaWatchUpdateUser,
  ),
];
